.form_inp {
  @apply w-full border border-[#cccccc] text-gray-700 h-12 px-4 outline-none duration-200 ease-in-out focus:border-green-500 focus:border-2 bg-[#f5f6fb] rounded-lg py-7;
}

.bathello_color {
  font-family: "Playfair Display", serif !important;
  @apply bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-blue-400 cursor-pointer font-black tracking-wide;
}
*::-webkit-scrollbar {
  display: none;
}

* {
  font-family: "Roboto Slab", serif !important;
  // border: 1px solid red !important;
}
