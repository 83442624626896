.nav_links {
  display: inline-block;
  position: relative;
  color: white;
}

.toggle_lang {
  @apply bg-black/0 flex px-1 absolute right-3 xl:right-10 items-center text-base outline-none text-white border border-white rounded-full xl:rounded-xl border-opacity-60 duration-500 hover:border-opacity-100 xl:text-xl opacity-90;
}

.bathello_color {
  font-family: "Playfair Display", serif !important;
  @apply bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-blue-400 cursor-pointer font-black tracking-wide;
}

.nav_links::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.nav_links:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.header_links {
  @apply nav_links cursor-pointer opacity-70 duration-300 hover:opacity-100 text-[10px] xl:text-xl;
}

.header {
  backdrop-filter: blur(2px);
  @apply bg-black/60  w-full rounded-bl-xl rounded-br-xl xl:rounded-bl-2xl xl:rounded-br-2xl;
}

.mobile_style {
  @apply hidden xl:flex;
}
