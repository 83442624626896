.Bheader {
  backdrop-filter: blur(2px);
  @apply w-full z-20 xl:px-0 xl:pr-10 fixed backdrop-blur-[5px] xl:py-3 pt-2 px-2 rounded-b-xl;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.header_navs {
  @apply xl:text-xl text-white;
  &:hover {
    @apply scale-105 text-blue-400 duration-300;
  }
}

.Bcategories_parents {
  @apply absolute z-10 w-full bottom-0;
}

.toggle_lang_bathello {
  @apply flex text-xl ml-10 bg-transparent text-white font-semibold outline-none;
}

.Bcategories_btn {
  border-bottom: 2px solid rgb(59 130 246 / var(--tw-text-opacity));
}

.bathello_color {
  font-family: "Playfair Display", serif;
  @apply bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-blue-400 tracking-wide;
}

.Bform_parent {
  @apply w-full h-20;
  label {
    @apply mt-4 text-base w-full relative;
    transition: 400ms;
  }
  input {
    @apply w-full text-lg mt-[1px] h-8;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.9);
    outline: none;
    transition: 4ms;
    background: transparent;
    transition: 400ms;
  }
  input:hover {
    border-bottom: 1px solid white;
  }
  span {
    @apply text-sm text-red-600 font-semibold;
  }
}
.black_border {
  border-bottom: 1px solid rgb(34 197 94 / var(--tw-border-opacity)) !important;
  @apply border-green-500;
}

.Bform_inp:focus ~ label,
.Bform_inp:valid ~ label {
  top: -20px;
  left: 0;
  color: #bdb8b8;
  font-size: 12px;
}
