.block_container::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateX(100%);
  border: 16px solid transparent;
  border-bottom: 16px solid rgba(255, 255, 255, 0.05);
  border-left: 16px solid rgba(255, 255, 255, 0.05);
}

.swiper_img {
  @apply w-full object-cover h-[300px] xl:max-h-full xl:h-full;
}

.profile_img {
  @apply w-28 xl:w-32 h-28 xl:h-32 object-scale-down rounded-full;
}

.mySwiper {
  position: relative;
  @apply h-full;
  z-index: -10;
  color: #000000e6;
}

.service_block {
  @apply flex flex-col w-fit items-center;
  .ul {
    @apply flex flex-col items-center gap-1;
    h3 {
      @apply xl:text-[28px] text-2xl;
    }
    .li_1 {
      @apply flex items-center;
      img {
        @apply w-5 xl:w-4;
      }
      p {
        @apply text-lg xl:text-base text-red-600;
      }
    }
    .li_2 {
      @apply flex items-center gap-1;
      img {
        @apply max-w-6 max-h-6;
      }
      p {
        @apply font-sans text-base text-[#2ecc71] xl:text-sm;
      }
    }
  }
}
