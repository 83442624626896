#shower_1 {
  @apply object-cover;
}
#shower_2 {
  @apply object-fill;
}
#shower_3 {
  @apply object-contain;
}
#mixer_2 {
  @apply object-fill;
}

.toilets {
  .swiper_img {
    @apply w-full object-fill h-[300px] xl:max-h-full xl:h-full;
  }
}

.bathRooms {
  .swiper_img {
    @apply w-80;
  }
}

.prods_swiper {
  @apply xl:w-[46%] h-[40%] xl:h-[55%] object-contain relative z-0 w-full;
  img {
    @apply rounded-2xl w-full;
  }
}

.cabinets_swiper {
  @apply xl:w-[30%] h-[48%] xl:h-[70%] object-cover w-full relative z-0;
  img {
    @apply rounded-2xl h-full;
  }
}

.toilets_swiper {
  @apply xl:w-[35%] h-[40%] xl:h-[60%] object-contain w-full relative z-0;
  img {
    @apply rounded-2xl;
  }
}
.sinks_swiper {
  @apply xl:w-[43%] h-[40%] xl:h-[70%] object-contain w-full relative z-0;
  img {
    @apply rounded-2xl;
  }
}
