.BMain_block {
  @apply absolute flex flex-col items-center w-[28%] px-3 py-8;
  h2 {
    @apply text-2xl cursor-auto mb-2 w-fit uppercase;
  }
}
.BMain_text {
  @apply text-6xl font-semibold overflow-hidden w-fit;
}

.bathello_color {
  font-family: "Playfair Display", serif !important;
  @apply bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-blue-400 tracking-wide;
}

.oasis_color {
  font-family: "Playfair Display", serif !important;
  @apply bg-clip-text text-transparent bg-gradient-to-tr from-green-600 to-green-400 tracking-wide;
}

.Bmain_content {
  .hide_img {
    @apply hidden xl:block;
  }
}

.about_swiper {
  @apply xl:w-[46%] w-full xl:h-[50%] relative z-0;
  img {
    @apply h-[100%] rounded-xl;
    display: block !important;
  }
}

.prods_swiper {
  @apply xl:w-[46%] h-[40%] xl:h-[55%] object-cover relative z-0 w-full;
  img {
    @apply rounded-2xl;
  }
}

.luxury_block {
  @apply absolute rounded-tl-3xl bg-[#3C4042] rounded-br-3xl top-36;
  h2 {
    @apply text-[#CCCCCC] font-semibold;
  }
  p {
    @apply text-[#CCCCCC] text-center;
  }
}
.bathello_block {
  @apply absolute left-1/2 rounded-lg bg-gradient-to-tr bg-black from-blue-600/70 to-blue-400/85 py-10 top-1/2 -translate-y-1/2 -translate-x-1/2;
  h2 {
    @apply bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-blue-400 tracking-wide;
    color: white;
  }
  p {
    @apply bg-clip-text text-transparent bg-gradient-to-r from-blue-800 to-blue-600 tracking-wide;
    color: white;
  }
}
.refined_block {
  @apply absolute rounded-tr-3xl bg-[#C0C0C0] rounded-bl-3xl right-5 top-36;
  h2 {
    @apply text-[#333333] font-medium;
  }
  p {
    @apply text-[#333333] font-medium;
  }
}

.BMain_section {
  @apply absolute top-0 w-full h-full duration-500;
}

.next_btn {
  @apply absolute font-mono left-1/2 -translate-x-1/2 bottom-0 bg-white rounded-full p-3;
  border: 1px solid rgba(0, 0, 0, 0.9);
}

.more_details_animation {
  @apply text-xl text-neutral-300 pb-1 hover:text-white duration-500 xl:text-2xl;
}

.more_details_animation {
  text-decoration: none;
  letter-spacing: 0.15rem;
}
.more_details_animation:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  @apply bg-blue-600;
  width: 0;
}
.more_details_animation:hover:after {
  width: 100%;
  left: 0;
}
.tab-button {
  padding: 16px 20px;
  border-radius: 6px;
  position: relative;
}

.tab-button::before {
  content: "";
  position: absolute;
  left: 0;
  border-radius: 16px;
  bottom: 0;
  width: 0%;
  height: 2px;
  background-color: rgb(37 99 235 / var(--tw-text-opacity));
  transition: 600ms ease-in-out;
}
.tab-button.tabs_active::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background-color: rgb(37 99 235 / var(--tw-text-opacity));
}

.BMain_title {
  font-family: "Playfair Display", serif !important;
  @apply font-bold;
}
