.main_page_bg {
  position: absolute;
  z-index: 0;
  backdrop-filter: blur(0.5px);
}
.main_text {
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 1;
  transform: translate(-50%, -50%);
  animation: slideInFromBottom 0.7s ease-in-out;
  font-family: "Merriweather", serif !important;
}

.submit_btn {
  @apply px-4 xl:px-4 py-1 xl:py-2 bg-gradient-to-tr to-[#e10808]/50 from-[#1130d1]/50 text-base xl:text-xl flex justify-center items-center rounded-xl xl:rounded-2xl text-white;
  color: linear-gradient(90deg, #e10808, #1130d1);
}

.CMain_title {
  @apply font-medium;
  font-weight: 400 !important;
}

.experience_block {
  @apply flex items-center flex-col justify-center w-1/3 gap-2 xl:gap-4;
  p {
    @apply text-[#e10808]/85 font-semibold text-3xl text-center xl:text-6xl;
  }
  span {
    @apply text-white text-center xl:text-center w-11/12 text-[10px] xl:text-2xl;
  }
}

.section_num {
  text-decoration: underline 4px;
  @apply text-[#808080] xl:text-[100px]  opacity-10 text-5xl absolute;
}

._3Section_blocks {
  @apply flex relative h-auto flex-col w-full xl:w-1/4 rounded-xl shadow-2xl;
  p {
    @apply text-2xl xl:text-2xl;
  }
  span {
    @apply text-lg xl:text-base flex;
  }
}
