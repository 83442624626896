.centerWrapper {
  box-shadow: 4px 2px 14px -1px black;
  @apply flex text-lg xl:text-xl w-full items-center gap-3 xl:gap-0 flex-wrap bg-black/60 text-white/90 pl-5 py-2 xl:px-5 xl:py-4 rounded;
  a {
    @apply mr-4;
  }
  .index_num {
    @apply font-sans text-gray-300;
  }
  .schedule_div {
    p {
      @apply text-lg;
    }
  }
}
