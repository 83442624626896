.service_title {
  font-weight: 700;
  letter-spacing: 1px;
  @apply w-full xl:w-11/12 xl:leading-[100%] text-[36px] sm:text-[44px] xl:text-[84px] text-center uppercase absolute z-10 left-1/2
    top-1/2 -translate-x-1/2 -translate-y-1/2 font-mono text-white/80;
  span {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
      "Liberation Mono", "Courier New", monospace;
  }
}
.logos_box {
  @apply flex items-center flex-wrap justify-center gap-8;
  li {
    img {
      object-fit: contain;
      @apply bg-white/5 w-40 h-40 xl:h-80 rounded-xl xl:w-80;
    }
  }
}
.list_btns {
  @apply flex justify-between xl:justify-center gap-2;
  button {
    @apply px-9 py-4 text-xl font-semibold rounded-lg;
  }
}
.centers_box {
  @apply bg-white/10 flex flex-col gap-5 w-fit xl:px-10 xl:py-10 px-6 py-6 rounded-lg;
}
